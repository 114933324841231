import QRCodeBase64Generator from 'qrcode';

const fullDescImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/member_full.svg`;
const pureEnVersionFullDescImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/pure_en_member_full.svg`;
const discountOnlyDescImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/member_discount_only.svg`;
const pureEnVersionDiscountOnlyDescImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/pure_en_member_discount_only.svg`;
const cashBackOnlyDescImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/member_cashback_only.svg`;
const pureEnVersionCashBackOnlyDescImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/pure_en_member_cashback_only.svg`;
const memberBonusDescImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/member_bonus.svg`;
const pureEnVersionMemberBonusDescImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/pure_en_member_bonus.svg`;
const memberNoBonusDescImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/member_no_bonus.svg`;
const pureEnVersionMemberNoBonusDescImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/pure_en_member_no_bonus.svg`;
const powerStatementImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/power_statement_low_letter.svg`;
const rechargeFeeAsLoyaltyMemberDescImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/top-up_as_loyalty_member.svg`;
const pureEnVersionRechargeFeeAsLoyaltyMemberDescImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/pure_en_top-up_as_loyalty_member.svg`;
const annualFeeAsLoyaltyMemberDescImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/annually_as_loyalty_member.svg`;
const pureEnVersionAnnualFeeAsLoyaltyMemberDescImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/pure_en_annually_as_loyalty_member.svg`;

const no_cashback_no_discount_no_prepaid_card_A5 = `${process.env.ASSETS_PREFIX}/assets/loyalty/no_cashback_no_discount_no_prepaid_card_loyalty_A5.svg`;
const pureEnVersion_no_cashback_no_discount_no_prepaid_card_A5 = `${process.env.ASSETS_PREFIX}/assets/loyalty/pure_en_no_cashback_no_discount_no_prepaid_card_loyalty_A5.svg`;
const dotLineImage = `${process.env.ASSETS_PREFIX}/assets/loyalty/dot_line.png`;

import { getPrepaidCardMaxBonusRatio, loadImage } from './utils';
import {
  QR_CODE_SIZE_STAND_A5,
  QR_CODE_SIZE_STAND_5X8_INCH,
  QR_CODE_SIZE_STICKER_4X6_INCH,
  QR_CODE_SIZE_STICKER_325_INCH,
  QR_CODE_SIZE_ACRYLIC_SHEETS_4X4_INCH,
  QR_CODE_SIZE_ACRYLIC_SHEETS_250X250_INCH,
} from '../consts';

const width = 420;
const height = 595;
const scale = 3;
const zhFontFamily = 'Source Han Sans';
const enFontFamily = 'Avenir Next Condensed';
const qrCodeContainerSize = 92 * scale;
const qrCodeSize = 80 * scale;
const qrLogoSize = 20 * scale;

function drawGradient(ctx) {
  const canvas = ctx.canvas;
  const width = canvas.width;
  const height = canvas.height;
  const x = width * 0.5;
  const y = 0;
  const rx = width * 0.6492;
  const ry = height * 0.5252;
  const radius = Math.max(rx, ry);
  const gradient = ctx.createRadialGradient(x, y, 0, x, y, radius);
  gradient.addColorStop(0, '#595966');
  gradient.addColorStop(1, '#1D1B2E');
  ctx.save();
  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, width, height);
  ctx.restore();
}

function drawOutline({ ctx, left, top, width, height }) {
  ctx.save();
  const x = left * scale;
  const y = top * scale;
  const gradient = ctx.createLinearGradient(x, y, x + width * scale, y + height * scale);
  gradient.addColorStop(0, '#FEE7C4');
  gradient.addColorStop(1, '#FBDEB1');
  ctx.strokeStyle = gradient;
  ctx.lineWidth = scale;
  ctx.strokeRect(x, y, width * scale, height * scale);
  ctx.restore();
}

async function drawTopSection({ ctx, restaurantInfo, pureEnVersion = false }) {
  const { name, foreign_name } = restaurantInfo;
  const maxWidth = width * 0.5 * scale;
  const fontSize = `${16 * scale}px/${24 * scale}px`;
  const color = '#EDD6B4';
  const fontZh = `normal ${fontSize} ${zhFontFamily}`;
  const { text: foreignNameText, contentWith: foreignNameWidth } = fittingStringEllipsis({
    context: ctx,
    str: foreign_name,
    maxWidth,
    font: fontZh,
  });

  if (!pureEnVersion) {
    ctx.save();
    ctx.fillStyle = color;
    ctx.textAlign = 'center';
    ctx.font = fontZh;
    ctx.fillText(foreignNameText, (width * scale) / 2, 45 * scale);
    ctx.restore();
  }

  const fontEn = `normal ${fontSize} ${enFontFamily}`;
  const { text: nameText, contentWith: nameWidth } = fittingStringEllipsis({
    context: ctx,
    str: name,
    maxWidth,
    font: fontEn,
  });
  ctx.save();
  ctx.fillStyle = color;
  ctx.textAlign = 'center';
  ctx.font = fontEn;
  ctx.fillText(nameText, (width * scale) / 2, pureEnVersion ? 58 * scale : 68 * scale);
  ctx.restore();

  const textMaxWidth = Math.max(pureEnVersion ? 0 : foreignNameWidth, nameWidth);
  const dotLineWidth = parseInt((304 * scale - textMaxWidth - 24 * scale * 2) / 2);
  const patternImage = await loadImage(dotLineImage);
  const pattern = ctx.createPattern(patternImage, 'repeat');
  ctx.save();
  ctx.fillStyle = pattern;
  const x1 = 57 * scale;
  const y = 53 * scale;
  ctx.fillRect(x1, y, dotLineWidth, 2);
  const x2 = width * scale - 57 * scale - dotLineWidth;
  ctx.fillRect(x2, y, dotLineWidth, 2);
  ctx.restore();
}

async function drawInch5x8TopSection({ ctx, restaurantInfo }) {
  const { name, foreign_name } = restaurantInfo;
  const maxWidth = width * 0.5 * scale;
  const fontSize = `${16 * scale}px/${24 * scale}px`;
  const color = '#EDD6B4';
  const fontZh = `normal ${fontSize} ${zhFontFamily}`;
  const { text: foreignNameText, contentWith: foreignNameWidth } = fittingStringEllipsis({
    context: ctx,
    str: foreign_name,
    maxWidth,
    font: fontZh,
  });
  ctx.save();
  ctx.fillStyle = color;
  ctx.textAlign = 'center';
  ctx.font = fontZh;
  ctx.fillText(foreignNameText, (width * scale) / 2, 45 * scale);
  ctx.restore();

  const fontEn = `normal ${fontSize} ${enFontFamily}`;
  const { text: nameText, contentWith: nameWidth } = fittingStringEllipsis({
    context: ctx,
    str: name,
    maxWidth,
    font: fontEn,
  });
  ctx.save();
  ctx.fillStyle = color;
  ctx.textAlign = 'center';
  ctx.font = fontEn;
  ctx.fillText(nameText, (width * scale) / 2, 68 * scale);
  ctx.restore();

  const textMaxWidth = Math.max(foreignNameWidth, nameWidth);
  const dotLineWidth = parseInt((304 * scale - textMaxWidth - 24 * scale * 2) / 2);
  const patternImage = await loadImage(dotLineImage);
  const pattern = ctx.createPattern(patternImage, 'repeat');
  ctx.save();
  ctx.fillStyle = pattern;
  const x1 = 57 * scale;
  const y = 53 * scale;
  ctx.fillRect(x1, y, dotLineWidth, 2);
  const x2 = width * scale - 57 * scale - dotLineWidth;
  ctx.fillRect(x2, y, dotLineWidth, 2);
  ctx.restore();
}

async function drawPromotionText({ ctx, loyaltySettings, prepaidCardSettings, pureEnVersion = false }) {
  let { cash_back_ratio = 0, discount_ratio = 1, join_member_mode } = loyaltySettings || {};
  cash_back_ratio *= 100;
  let cashBackDesc = `${cash_back_ratio}%`;
  const zhDiscount = (discount_ratio * 1000) / 100;
  const discountZHDesc = `${zhDiscount}`;
  const enDiscount = parseInt(100 - discount_ratio * 100);
  const discountENDesc = `${enDiscount}%`;
  const hasDiscount = +discount_ratio !== 1;
  const x = 58 * scale;
  const y = 100 * scale;

  if (join_member_mode === 'recharge_join') {
    let image = null;
    if (pureEnVersion) {
      image = await loadImage(pureEnVersionRechargeFeeAsLoyaltyMemberDescImage);
      ctx.drawImage(image, x, y, 304 * scale, 114 * scale);
    } else {
      image = await loadImage(rechargeFeeAsLoyaltyMemberDescImage);
      ctx.drawImage(image, x, y, 304 * scale, 165 * scale);
    }
    return;
  }
  if (join_member_mode === 'annual_fee_join') {
    let image = null;
    if (pureEnVersion) {
      image = await loadImage(pureEnVersionAnnualFeeAsLoyaltyMemberDescImage);
      ctx.drawImage(image, x, y, 304 * scale, 114 * scale);
    } else {
      image = await loadImage(annualFeeAsLoyaltyMemberDescImage);
      ctx.drawImage(image, x, y, 304 * scale, 150 * scale);
    }
    return;
  }

  //has dscount and cash back
  if (hasDiscount && cash_back_ratio > 0) {
    if (pureEnVersion) {
      const image = await loadImage(pureEnVersionFullDescImage);
      ctx.drawImage(image, x, y, 304 * scale, 176 * scale);

      ctx.save();
      ctx.textAlign = 'center';
      ctx.fillStyle = '#2B293B';
      ctx.font = `bold normal ${42 * scale}px/${65 * scale}px ${enFontFamily}`;
      ctx.fillText(discountENDesc, 170 * scale, 203 * scale, 74 * scale);
      ctx.fillStyle = '#FEE5C0';
      ctx.font = `bold normal ${32 * scale}px/${44 * scale}px ${enFontFamily}`;
      ctx.fillText(cashBackDesc, 205 * scale, 265 * scale, 52 * scale);
      ctx.restore();
    } else {
      const image = await loadImage(fullDescImage);
      ctx.drawImage(image, x, y, 304 * scale, 208 * scale);
      ctx.save();
      ctx.textAlign = 'center';
      ctx.fillStyle = '#2F2E3F';
      ctx.font = `bold normal ${36 * scale}px/${49 * scale}px ${enFontFamily}`;
      ctx.fillText(discountZHDesc, 148 * scale, 190 * scale, 28 * scale);
      ctx.fillText(cashBackDesc, 264 * scale, 190 * scale, 48 * scale);
      ctx.restore();

      ctx.save();
      ctx.textAlign = 'center';
      ctx.fillStyle = '#FEE5C0';
      ctx.font = `bold normal ${42 * scale}px/${65 * scale}px ${enFontFamily}`;
      ctx.fillText(discountENDesc, 164 * scale, 255 * scale, 74 * scale);
      ctx.font = `bold normal ${32 * scale}px/${44 * scale}px ${enFontFamily}`;
      ctx.fillText(cashBackDesc, 205 * scale, 298 * scale, 52 * scale);
      ctx.restore();
    }
    return;
  }
  //has dscount and no cash back
  if (hasDiscount && cash_back_ratio <= 0) {
    if (pureEnVersion) {
      const image = await loadImage(pureEnVersionDiscountOnlyDescImage);
      ctx.drawImage(image, x, y, 304 * scale, 135 * scale);

      ctx.save();
      ctx.textAlign = 'center';
      ctx.fillStyle = '#FEE5C0';
      ctx.font = `bold normal ${42 * scale}px/${65 * scale}px ${enFontFamily}`;
      ctx.fillText(discountENDesc, 163 * scale, 219 * scale, 74 * scale);
      ctx.restore();

      return;
    } else {
      const image = await loadImage(discountOnlyDescImage);
      ctx.drawImage(image, x, y, 304 * scale, 177 * scale);
      ctx.save();
      ctx.textAlign = 'center';
      ctx.fillStyle = '#2F2E3F';
      ctx.font = `bold normal ${52 * scale}px/${71 * scale}px ${enFontFamily}`;
      ctx.fillText(discountZHDesc, 286 * scale, 202 * scale, 52 * scale);
      ctx.restore();

      ctx.save();
      ctx.textAlign = 'center';
      ctx.fillStyle = '#FEE5C0';
      ctx.font = `bold normal ${42 * scale}px/${65 * scale}px ${enFontFamily}`;
      ctx.fillText(discountENDesc, 163 * scale, 263 * scale, 74 * scale);
      ctx.restore();
      return;
    }
  }

  //no dscount and has cash back
  if (!hasDiscount && cash_back_ratio > 0) {
    if (pureEnVersion) {
      const image = await loadImage(pureEnVersionCashBackOnlyDescImage);
      ctx.drawImage(image, x, y, 304 * scale, 120 * scale);
      ctx.save();
      ctx.textAlign = 'center';
      ctx.fillStyle = '#FEE5C0';
      ctx.font = `bold normal ${32 * scale}px/${44 * scale}px ${enFontFamily}`;
      ctx.fillText(cashBackDesc, 204 * scale, 209 * scale, 53 * scale);
      ctx.restore();
      return;
    } else {
      const image = await loadImage(cashBackOnlyDescImage);
      ctx.drawImage(image, x, y, 304 * scale, 164 * scale);
      ctx.save();
      ctx.textAlign = 'center';
      ctx.fillStyle = '#2F2E3F';
      ctx.font = `bold normal ${40 * scale}px/${55 * scale}px ${enFontFamily}`;
      ctx.fillText(cashBackDesc, 232 * scale, 195 * scale, 70 * scale);
      ctx.restore();

      ctx.save();
      ctx.textAlign = 'center';
      ctx.fillStyle = '#FEE5C0';
      ctx.font = `bold normal ${32 * scale}px/${44 * scale}px ${enFontFamily}`;
      ctx.fillText(cashBackDesc, 202 * scale, 252 * scale, 53 * scale);
      ctx.restore();
      return;
    }
  }

  const maxRatio = Math.floor(getPrepaidCardMaxBonusRatio(prepaidCardSettings) * 100);
  //no discount and no cashback ratio and no prepaid card
  if (!hasDiscount && cash_back_ratio === 0 && maxRatio === 0) {
    if (pureEnVersion) {
      const image = await loadImage(pureEnVersion_no_cashback_no_discount_no_prepaid_card_A5);
      ctx.drawImage(image, x, y, 306 * scale, 114 * scale);
      return;
    } else {
      const image = await loadImage(no_cashback_no_discount_no_prepaid_card_A5);
      ctx.drawImage(image, x, y, 306 * scale, 153 * scale);
      return;
    }
  }

  if (maxRatio >= 1) {
    if (pureEnVersion) {
      const image = await loadImage(pureEnVersionMemberBonusDescImage);
      ctx.drawImage(image, x, y, 304 * scale, 117 * scale);
      const bonusRatioDesc = `${maxRatio}%`;
      ctx.save();
      ctx.textAlign = 'center';
      ctx.fillStyle = '#FEE5C0';
      ctx.font = `bold normal ${30 * scale}px/${41 * scale}px ${enFontFamily}`;
      ctx.fillText(bonusRatioDesc, 252 * scale, 207 * scale, 45 * scale);
      ctx.restore();
      return;
    } else {
      const image = await loadImage(memberBonusDescImage);
      ctx.drawImage(image, x, y, 304 * scale, 154 * scale);
      const bonusRatioDesc = `${maxRatio}%`;
      ctx.save();
      ctx.textAlign = 'center';
      ctx.fillStyle = '#2F2E3F';
      ctx.font = `bold normal ${36 * scale}px/${49 * scale}px ${enFontFamily}`;
      ctx.fillText(bonusRatioDesc, 327 * scale, 189 * scale, 50 * scale);
      ctx.restore();

      ctx.save();
      ctx.textAlign = 'center';
      ctx.fillStyle = '#FEE5C0';
      ctx.font = `bold normal ${30 * scale}px/${41 * scale}px ${enFontFamily}`;
      ctx.fillText(bonusRatioDesc, 252 * scale, 244 * scale, 45 * scale);
      ctx.restore();
      return;
    }
  }

  // no bonus
  if (pureEnVersion) {
    const image = await loadImage(pureEnVersionMemberNoBonusDescImage);
    ctx.drawImage(image, x, y, 304 * scale, 125 * scale);
  } else {
    const image = await loadImage(memberNoBonusDescImage);
    ctx.drawImage(image, x, y, 304 * scale, 161 * scale);
  }
}

export const ELLIPSIS_TEXT = '…';

export function fittingStringEllipsis({ context, str, maxWidth, font }) {
  context.save();
  context.font = font;
  let width = context.measureText(str).width;
  if (width <= maxWidth) {
    return {
      text: str,
      contentWith: width,
    };
  }

  const ellipsisWidth = context.measureText(ELLIPSIS_TEXT).width;
  let len = str.length;
  while (width >= maxWidth - ellipsisWidth && len-- > 0) {
    str = str.substring(0, len);
    width = context.measureText(str).width;
  }

  context.restore();

  return {
    text: str + ELLIPSIS_TEXT,
    contentWith: width,
  };
}

async function drawQRCodeSection({ ctx, loyaltySettings, restaurantInfo, pureEnVersion = false }) {
  let y = 366 * scale;
  const x1 = (width * scale - qrCodeContainerSize) / 2;
  drawRoundRect({ ctx, width: qrCodeContainerSize, height: qrCodeContainerSize, x: x1, y, radius: 8 * scale });
  y += 6 * scale;
  const { qr_code_url } = loyaltySettings;
  const qrCodeImage = await generateQRCode(qr_code_url, qrCodeSize);
  const x2 = (width * scale - qrCodeSize) / 2;
  ctx.drawImage(qrCodeImage, x2, y, qrCodeSize, qrCodeSize);
  const { logo_url } = restaurantInfo;
  const logoImage = await loadImage(logo_url);

  if (logoImage) {
    y += (qrCodeSize - qrLogoSize) / 2;
    const x3 = (width * scale - qrLogoSize) / 2;
    ctx.drawImage(logoImage, x3, y, qrLogoSize, qrLogoSize);
  }

  const centerX = (width * scale) / 2;
  const canvas = ctx.canvas;
  if (!pureEnVersion) {
    y = (366 + 20) * scale + qrCodeContainerSize;
    ctx.save();
    canvas.style.letterSpacing = '0.24em';
    ctx.textAlign = 'center';
    ctx.font = `bold normal ${14 * scale}px/${21 * scale}px ${zhFontFamily}`;
    ctx.fillStyle = '#FEE6C1';
    ctx.fillText('入会二维码', centerX, y);
    ctx.restore();

    y += 14 * scale;
    ctx.save();
    canvas.style.letterSpacing = 0;
    ctx.textAlign = 'center';
    ctx.font = `bold normal ${12 * scale}px/${16 * scale}px ${enFontFamily}`;
    ctx.fillStyle = '#FEE6C1';
    ctx.fillText('Sign up QR Code', centerX, y);
    ctx.restore();
  } else {
    y = (366 + 20) * scale + qrCodeContainerSize;
    ctx.save();
    canvas.style.letterSpacing = 0;
    ctx.textAlign = 'center';
    ctx.font = `bold normal ${12 * scale}px/${16 * scale}px ${enFontFamily}`;
    ctx.fillStyle = '#FEE6C1';
    ctx.fillText('Sign up QR Code', centerX, y);
    ctx.restore();
  }
}

async function drawPowerStatement(ctx) {
  const image = await loadImage(powerStatementImage);
  const x = ((width - 138) / 2) * scale;
  ctx.drawImage(image, x, (height - 16 - 12) * scale, 138 * scale, 16 * scale);
}

export async function generateEmptyPDF(size) {
  const jsPDFModule = await import('jspdf' /* webpackChunkName:"pdf" */);
  const jsPDF = jsPDFModule.default;
  const pdfConfig = { orientation: 'portrait' };
  switch (size) {
    case QR_CODE_SIZE_STAND_A5: {
      pdfConfig.format = 'a5';
      pdfConfig.unit = 'px';
      break;
    }
    case QR_CODE_SIZE_STAND_5X8_INCH: {
      pdfConfig.format = [5, 8];
      pdfConfig.unit = 'in';
      break;
    }
    case QR_CODE_SIZE_STICKER_4X6_INCH: {
      pdfConfig.format = [4, 6];
      pdfConfig.unit = 'in';
      break;
    }
    case QR_CODE_SIZE_STICKER_325_INCH: {
      pdfConfig.format = [3.25, 3.25];
      pdfConfig.unit = 'in';
      break;
    }
    case QR_CODE_SIZE_ACRYLIC_SHEETS_4X4_INCH: {
      pdfConfig.format = [4, 4];
      pdfConfig.unit = 'in';
      break;
    }
    case QR_CODE_SIZE_ACRYLIC_SHEETS_250X250_INCH: {
      pdfConfig.format = [2.5, 2.5];
      pdfConfig.unit = 'in';
      break;
    }

    default: {
      pdfConfig.format = 'a5';
      pdfConfig.unit = 'px';
    }
  }
  // if (size === QR_CODE_SIZE_STAND_A5) {
  //   pdfConfig.format = 'a5';
  //   pdfConfig.unit = 'px';
  // } else {
  //   pdfConfig.format = [4, 6];
  //   pdfConfig.unit = 'in';
  // }
  return jsPDF(pdfConfig);
}

export async function generatePDFWithImages({ images, size }) {
  const pdf = await generateEmptyPDF(size);
  addImagesToPDF({ pdf, images });
  return pdf;
}

export function addImageToPDF({ pdf, pageNumber, image }) {
  if (pageNumber > 1) pdf.addPage();
  pdf.setPage(pageNumber);
  const { pageSize } = pdf.internal;
  const width = pageSize.getWidth();
  const height = pageSize.getHeight();
  pdf.addImage({
    imageData: image,
    format: 'PNG',
    x: 0,
    y: 0,
    width,
    height,
    compression: 'FAST',
  });
}

export function addImagesToPDF({ pdf, images, startPage = 0 }) {
  for (let i = 0; i < images.length; i++) {
    const pageNumber = startPage + i + 1;
    addImageToPDF({ pdf, pageNumber, image: images[i] });
  }
}

export async function generateQRCode(qrCodeLink, size) {
  let image;

  try {
    const url = await QRCodeBase64Generator.toDataURL(qrCodeLink, {
      errorCorrectionLevel: 'Q',
      width: size,
      margin: 0,
    });
    image = await loadImage(url);
  } catch (e) {
    image = null;
  }

  return image;
}

export function drawRoundRect({ ctx, width, height, radius, x, y, fillColor = '#fff' }) {
  ctx.save();
  ctx.fillStyle = fillColor;
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
  ctx.fill();
  ctx.restore();
}

// pass a html canvas to set letter-spacing
export async function drawMixedLoyaltyMemberQRCodePDF({ loyaltySettings, prepaidCardSettings, restaurantInfo, size }) {
  const images = [];
  if (size === QR_CODE_SIZE_STAND_A5) {
    let someImageForPDF = await drawA5LoyaltyMemberQRCodePDF({
      loyaltySettings,
      prepaidCardSettings,
      restaurantInfo,
      size,
      exportImage: true,
    });
    images.push(someImageForPDF);
    someImageForPDF = await drawPureEnVersionA5LoyaltyMemberQRCodePDF({
      loyaltySettings,
      prepaidCardSettings,
      restaurantInfo,
      size,
      exportImage: true,
    });
    images.push(someImageForPDF);
    return await generatePDFWithImages({ images, size });
  } else {
    let someImageForPDF = await drawIn5x8LoyaltyMemberQRCodePDF({
      loyaltySettings,
      prepaidCardSettings,
      restaurantInfo,
      size,
      exportImage: true,
    });
    images.push(someImageForPDF);
    someImageForPDF = await drawPureEnVersionIn5x8LoyaltyMemberQRCodePDF({
      loyaltySettings,
      prepaidCardSettings,
      restaurantInfo,
      size,
      exportImage: true,
    });
    images.push(someImageForPDF);
    return await generatePDFWithImages({ images, size });
  }
}

export async function drawA5LoyaltyMemberQRCodePDF({
  loyaltySettings,
  prepaidCardSettings,
  restaurantInfo,
  size,
  exportImage = false,
}) {
  const canvas = document.createElement('canvas');
  canvas.width = width * scale;
  canvas.height = height * scale;
  canvas.style.position = 'fixed';
  canvas.style.left = '-9999px';
  canvas.style.top = '0px';
  document.body.appendChild(canvas);
  const ctx = canvas.getContext('2d');
  drawGradient(ctx);
  drawOutline({ ctx, left: 12, top: 12, width: 396, height: 543 });
  await drawTopSection({ ctx, restaurantInfo });
  await drawPromotionText({ ctx, loyaltySettings, prepaidCardSettings });
  await drawQRCodeSection({ ctx, loyaltySettings, restaurantInfo });
  await drawPowerStatement(ctx);
  const image = canvas.toDataURL();
  document.body.removeChild(canvas);
  if (exportImage) {
    return image;
  }
  return await generatePDFWithImages({ images: [image], size });
}

async function drawPureEnVersionA5LoyaltyMemberQRCodePDF({
  loyaltySettings,
  prepaidCardSettings,
  restaurantInfo,
  size,
  exportImage = false,
}) {
  const canvas = document.createElement('canvas');
  canvas.width = width * scale;
  canvas.height = height * scale;
  canvas.style.position = 'fixed';
  canvas.style.left = '-9999px';
  canvas.style.top = '0px';
  document.body.appendChild(canvas);
  const ctx = canvas.getContext('2d');
  drawGradient(ctx);
  drawOutline({ ctx, left: 12, top: 12, width: 396, height: 543 });
  await drawTopSection({ ctx, restaurantInfo, pureEnVersion: true });
  await drawPromotionText({ ctx, loyaltySettings, prepaidCardSettings, pureEnVersion: true });
  await drawQRCodeSection({ ctx, loyaltySettings, restaurantInfo, pureEnVersion: true });
  await drawPowerStatement(ctx);
  const image = canvas.toDataURL();
  document.body.removeChild(canvas);
  if (exportImage) {
    return image;
  }
  return await generatePDFWithImages({ images: [image], size });
}

export async function drawIn5x8LoyaltyMemberQRCodePDF({
  loyaltySettings,
  prepaidCardSettings,
  restaurantInfo,
  size,
  exportImage = false,
}) {
  const canvas = document.createElement('canvas');
  canvas.width = 360 * scale;
  canvas.height = 576 * scale;
  canvas.style.position = 'fixed';
  canvas.style.left = '-9999px';
  canvas.style.top = '0px';
  document.body.appendChild(canvas);
  const ctx = canvas.getContext('2d');
  drawGradient(ctx);
  drawOutline({ ctx, left: 10, top: 10, width: 340, height: 460 });
  //设定和A5的缩放比
  ctx.scale(360 / 420, 500 / 595);
  await drawTopSection({ ctx, restaurantInfo });
  await drawPromotionText({ ctx, loyaltySettings, prepaidCardSettings });
  await drawQRCodeSection({ ctx, loyaltySettings, restaurantInfo });
  await drawPowerStatement(ctx);
  const image = canvas.toDataURL();
  document.body.removeChild(canvas);
  if (exportImage) {
    return image;
  }
  return await generatePDFWithImages({ images: [image], size });
}

export async function drawPureEnVersionIn5x8LoyaltyMemberQRCodePDF({
  loyaltySettings,
  prepaidCardSettings,
  restaurantInfo,
  size,
  exportImage = false,
}) {
  const canvas = document.createElement('canvas');
  canvas.width = 360 * scale;
  canvas.height = 576 * scale;
  canvas.style.position = 'fixed';
  canvas.style.left = '-9999px';
  canvas.style.top = '0px';
  document.body.appendChild(canvas);
  const ctx = canvas.getContext('2d');
  drawGradient(ctx);
  drawOutline({ ctx, left: 10, top: 10, width: 340, height: 460 });
  //设定和A5的缩放比
  ctx.scale(360 / 420, 500 / 595);
  await drawTopSection({ ctx, restaurantInfo, pureEnVersion: true });
  await drawPromotionText({ ctx, loyaltySettings, prepaidCardSettings, pureEnVersion: true });
  await drawQRCodeSection({ ctx, loyaltySettings, restaurantInfo, pureEnVersion: true });
  await drawPowerStatement(ctx);
  const image = canvas.toDataURL();
  document.body.removeChild(canvas);
  if (exportImage) {
    return image;
  }
  return await generatePDFWithImages({ images: [image], size });
}
